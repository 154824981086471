body {
  font-family: verdana;
}india trading academy


      body{
          padding: 0;
          margin: 0;
      }
      *{
          font-family: 'lato';
      }
      .team-logo{
          height: 100px;
          width: 100px;
          border-radius: 50%;
          background-size: cover !important;
          margin: 20px auto;
          text-align: center;
          margin-top: 40px;
      }
      .blue-header{
          background: #32B0ED;
          color: #fff;
          padding: 10px 15px;
text-align:center;
      }
      .avitr-t-name{
          position: relative;
        ;
      }
   
    .team-logo-wrapper{
        text-align: center;
    }
    .text-wrapper{
        padding:15px;
        padding-top: 0;
    }
    .gray-text{
        color: #999;
        font-size: 12px;
        margin: 0;
    }
    .view-btn{
      height: 39px;
      line-height: 39px;
      padding: 0 20px;
      background: #31D783;
      color: #fff;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      display: inline-block;
      border-radius: 35px;
      text-decoration: none;
      margin: 15px 0;
    }
    .avitor-icon-link{
        position: relative;
     
    }
   .avitor-icon-link img{
    
      height: 17px;
     
   }
   .user-icon{
       height: 15px;
   }
   .info-text{
       font-size: 16px;
       color: 000;
   }
   .Link-channer{
       margin-top: 50px;
       font-size: 16px;
       
   }
   .avitr-t-name{
       font-size: 22px;
       margin: 5px 0;
   }
   .info-link-text, .avitor-link{
       color: #999;
       text-decoration: none;
       font-size: 13px;
   }
   .info-link-text img {
          height: 11px !important;
      }
  
.bg-team {
  background: url("./school_of_nifty.png");
}